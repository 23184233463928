import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { Email, LinkedIn, Twitter } from "@mui/icons-material";
// import ctaImage from "../assets/undraw_online_art.svg";
import backgroundImage from "../assets/endless-constellation.svg";
import { useIsMobile } from "../hooks/isMobile";

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 24px;
  & * {
    font-family: "Poppins", sans-serif;
  }
  align-items: center;
`;

const TopSection = styled.div`
  width: 100%;
  background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
  /* height: 500px; */
  height: 300px;
  padding: 24px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  max-width: 800px;
`;

const Logo = styled.h2`
  color: white;
`;

const CallToActionBlurb = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  width: 400px;
  gap: 12px;
`;

// const CallToActionBlurbSub = styled.div`
//   font-family: "Raleway", sans-serif;
//   line-height: 1.5;
// `;

// const CTAButton = styled(Button)`
//   background: #3a47d5;
//   border-radius: 36px;
//   color: white;
//   width: fit-content;
//   padding: 12px;
//   font-family: "Poppins";
//   &:hover {
//     background: #5c69f7;
//   }
// `;

const CTAParent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  width: 100%;
`;

const Body = styled.div`
  padding: 24px 48px;
  flex: 1;
  /* background: url(${backgroundImage}); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// const BodyBlurb = styled.div`
//   width: 400px;
//   text-align: center;
// `;

const ContactUsParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
  max-width: 800px;
`;

// const Subtext = styled.p`
//   color: #555;
// `;

export const Home = () => {
  const isMobile = useIsMobile();
  return (
    <Root>
      <TopSection style={{ height: isMobile ? "fit-content" : undefined }}>
        <Header>
          <Logo>Adventure Networks</Logo>
        </Header>

        <CTAParent>
          <CallToActionBlurb
            style={{
              width: isMobile ? "100%" : undefined
            }}
          >
            <h1>Pioneering in frontier technologies</h1>
          </CallToActionBlurb>
        </CTAParent>
      </TopSection>
      <Body>
        {/* <BodyBlurb
          style={{
            width: isMobile ? "100%" : undefined
          }}
        >
          <h1>Innovation Unleashed</h1>
          <Subtext>
            Our roots lie in a strong desire to innovate. Led by our founder, a
            Y-Combinator alum, we have a seasoned team of builders with decades
            of experience in software development.
          </Subtext>
        </BodyBlurb>

        <HorizontalRule
          style={{
            // width: "100%",
            width: 100,
            height: 2,
            background: "#ccc",
            margin: "24px 0"
          }}
        />

        <div style={{ height: 24 }} /> */}

        <ContactUsParent>
          <h1>Contact Us</h1>
          {/* <Subtext>Work with us to create amazing products</Subtext> */}
          <Button
            startIcon={<LinkedIn sx={{ fill: "white" }} />}
            href="https://www.linkedin.com/company/adventurenetworks"
            target="_blank"
            variant="contained"
            style={{ textTransform: "none" }}
          >
            LinkedIn
          </Button>

          <Button
            startIcon={<Email sx={{ fill: "white" }} />}
            href="mailto:mhoydich@gmail.com"
            variant="contained"
            style={{ textTransform: "none" }}
          >
            Email
          </Button>

          <Button
            startIcon={<Twitter sx={{ fill: "white" }} />}
            href="https://twitter.com/imageappdotxyz"
            target="_blank"
            variant="contained"
            style={{ textTransform: "none" }}
          >
            Twitter
          </Button>
        </ContactUsParent>

        <div style={{ height: 24 }} />
      </Body>
    </Root>
  );
};
