import "./App.css";
import React from "react";
import {
  Redirect,
  Route,
  HashRouter as Router,
  Switch
} from "react-router-dom";
import { Home } from "./routes/Home";
const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default App;
